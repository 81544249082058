import React from 'react';
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import EmptyPage from '../EmptyPage';
import NotFoundPage from '../NotFoundPage';
import StreamPage from '../StreamPage';
import EditPage from '../EditPage';
import RecentPage from '../RecentPage';
import UploadFilePage from '../RecentPage/upload_file_page';
import LoginPage from '../LoginPage';
import './index.scss';


const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;


const AppContext = React.createContext(null);


function App() {
  const navigate = useNavigate();

  return (
    <AppContext.Provider value={{ navigate: navigate }}>
      <Routes>
        <Route path='/' element={<AppContainer navigate={navigate} />}>
          <Route index element={<StreamPage />} />
          <Route path='edit/:pin_id' element={<EditPage />} />
          <Route path='recent' element={<RecentPage />} />
          <Route path='upload_file' element={<UploadFilePage />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='about' element={<EmptyPage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Route>
      </Routes>
    </AppContext.Provider>
  );
}

function AppContainer() {
  return (
    <div>
      <Layout >
        <Content>
          <div className='app-content'>
            <div className='app-content-side-space' />
            <div className='app-content-middle'>
              <Outlet />
            </div>
            <div className='app-content-side-space' />
          </div>
        </Content>
      </Layout>
    </div>
  );
}

export default App;