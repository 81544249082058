import moment from 'moment';
import PubSub from 'pubsub-js';
import 'moment/locale/zh-cn';
import React, { useState, createContext, useContext, useEffect, useRef } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { message, Typography, Divider, Table, Button, DatePicker, Calendar, Space, Checkbox, Input, Upload } from 'antd';
import { marked } from 'marked';
import { queryId, savePinlog, deepCompare, fileToImageElementText, appendLogContent, pasteImageAndUpload, insertLogContent, applyRule } from '../DataModel';
import { InboxOutlined, UploadOutlined, EditOutlined, HomeOutlined } from '@ant-design/icons';
import { uploadImageProps, uploadFileProps, uploadBigFileProps } from '../UI';
import { useHotkeys } from 'react-hotkeys-hook';
import './index.scss';
import './preview.scss';

const { Title, Paragraph, Text, Link } = Typography;

const EditPageContext = createContext();
const ON_REFRESHED = 'edit_page_on_refreshed';
const COLUMN_ONE = 'one';
const COLUMN_TWO = 'two';
const DEFAULT_EDITOR_CONFIG = {
  'layout': COLUMN_TWO
}


function timestampToText(timestamp) {
  if (timestamp === null) {
    return '<Empty>';
  }
  return moment(timestamp * 1000).format('YYYY-MM-DD, HH:mm:ss, ddd');
}

// ====== refresh & save ======

async function save(pinlog) {
  if (pinlog === null) {
    return;
  }
  const [error, response] = await savePinlog(pinlog);
  if (error === null) {
    if (response.data.code == 0) {
      // 先调用下刷新
      await refresh(pinlog.pin.pin_id);
      // 再提示保持成功
      message.info('Save Success.')
    }
    else {
      message.error(response.data.error_message)
    }
  }
  else {
    console.error(error);
    message.error('Save Failed!')
  }
}

async function refresh(inputPinId, notSendMessage) {
  const [error, queryPinlog] = await queryId(inputPinId);
  if (error !== null) {
    console.error(error);
    message.error('Refresh Failed!')
  }
  if (error === null && queryPinlog !== null) {
    PubSub.publish(ON_REFRESHED, { queryPinlog, notSendMessage });
    if (notSendMessage !== true) {
      message.info('Refresh Success.')
    }
  }
}


function TopButton() {
  const context = useContext(EditPageContext);
  const { pinlog, editorConfig, setEditorConfig } = context;
  const layout = editorConfig && editorConfig.layout;
  const title = pinlog && pinlog.pin && pinlog.pin.title;
  const pin_id = pinlog && pinlog.pin && pinlog.pin.pin_id;

  let buttonLayoutName = 'Column : Default';
  if (!layout || layout === COLUMN_ONE) {
    buttonLayoutName = 'Column : One';
  }
  else if (layout === COLUMN_TWO) {
    buttonLayoutName = 'Column : Two';
  }

  return (
    <div id='top-button-container'>
      <Space size='large'>
        <Button className='top-button' onClick={() => { refresh(pinlog.pin.pin_id) }}>Refresh</Button>
        <Button className='top-button' type='primary' onClick={() => { save(pinlog) }}>Save</Button>
        <Button className='top-button' type='primary' danger>Delete</Button>
        <div style={{ width: 100 }} />
        <Button className='top-button' style={{ width: 130 }} onClick={() => {
          if (!layout || layout === COLUMN_ONE) {
            setEditorConfig(Object.assign({}, editorConfig, { layout: COLUMN_TWO }));
          }
          else if (layout === COLUMN_TWO) {
            setEditorConfig(Object.assign({}, editorConfig, { layout: COLUMN_ONE }));
          }
        }}>{buttonLayoutName}</Button>
      </Space>
      <Space size='large'>
        <Text><strong>Title : &nbsp;&nbsp;&nbsp;</strong>{title}</Text>
        <Link href={`pinlog-cli://edit_${pin_id}_subl`}><EditOutlined /></Link>
        <Link href='\' target="_blank"><HomeOutlined /></Link>
      </Space>
    </div>
  )
}

function ContentPreview(props) {
  let content = props.content || '';
  let columnLayout = props.columnLayout || 'column-default';

  useEffect(() => {
    // 配置marked
    marked.setOptions({
      renderer: new marked.Renderer(),
      gfm: true, //默认为true。 允许 Git Hub标准的markdown.
      tables: true, //默认为true。 允许支持表格语法。该选项要求 gfm 为true。
      breaks: true, //默认为false。 允许回车换行。该选项要求 gfm 为true。
    });
  }, []);

  const target = applyRule(content);

  return (
    <div className={`markdown-preview ${columnLayout}`} dangerouslySetInnerHTML={{
      __html: marked(target)
    }}>
    </div>
  )
}

function updateDocumentTitle(pinTitle) {
  if (pinTitle === null || pinTitle.length === 0) {
    document.title = 'Untitled - PinLog';
  } else {
    document.title = `${pinTitle} - PinLog`
  }
}

function switchDocumentTitleChanged(changed) {
  if (changed) {
    if (document.title.slice(0, 1) !== '*') {
      document.title = '* ' + document.title;
    }
  } else {
    if (document.title.slice(0, 1) === '*') {
      document.title = document.title.slice(2);
    }
  }
}

function EditPage() {
  const [pinId, setPinId] = useState('');
  const [pinlog, setPinlog] = useState({});
  const [editorConfig, setEditorConfig] = useState(DEFAULT_EDITOR_CONFIG);
  const [rawPinlog, setRawPinlog] = useState({});  // 未修改的 pinlog
  const [viewTimestamp, setViewTimestamp] = useState(null);  // DatePicker 的时间
  const [searchParams, setSearchParams] = useSearchParams();
  const [twoContainerHeight, setTwoContainerHeight] = useState(null);

  // 监听 ON_REFRESHED
  useEffect(() => {
    const token = PubSub.subscribe(ON_REFRESHED, (msg, data) => {
      const { queryPinlog, notSendMessage } = data;
      setPinlog(queryPinlog);
      setRawPinlog(queryPinlog);
      setViewTimestamp(moment(queryPinlog.pin.pin_timestamp * 1000));
      updateDocumentTitle(queryPinlog.pin.title);
      // 自动选择布局
      if (notSendMessage) {
        const content = queryPinlog.log && queryPinlog.log.content;
        if (content && content.split('\n').length > 40) {
          setEditorConfig(Object.assign({}, editorConfig, { layout: COLUMN_TWO }));
        }
      }
    });
    return () => { PubSub.unsubscribe(token) };
  }, [])

  // 未保存的修改
  useEffect(() => {
    const changed = !deepCompare(pinlog, rawPinlog);
    switchDocumentTitleChanged(changed);
  })
  // 关闭标签页提示
  useEffect(() => {
    if (!deepCompare(pinlog, rawPinlog)) {
      const listener = ev => {
        ev.preventDefault();
        ev.returnValue = '有未保存的修改，确定离开吗？';
      };
      window.addEventListener('beforeunload', listener);

      return () => {
        window.removeEventListener('beforeunload', listener)
      }
    }
  }, [pinlog])
  // 粘贴图片
  useEffect(() => {
    const onPasteImage = async (e) => {
      const imageContent = await pasteImageAndUpload(e);
      if (imageContent === null) {
        return;
      }

      insertTextToContent(e, imageContent);
    }
    // 绑定事件
    const contentInputDomList = document.getElementsByClassName("content-input");
    for (let contentInputDom of contentInputDomList) {
      contentInputDom.addEventListener('paste', onPasteImage);
    }
    // 解绑事件
    return () => {
      for (let contentInputDom of contentInputDomList) {
        contentInputDom.removeEventListener('paste', onPasteImage)
      }
    };
  }, [pinlog])
  // 插入文本
  const insertTextToContent = (e, insertText) => {
    const insertPosition = e.target.selectionStart;
    setPinlog(insertLogContent(pinlog, insertPosition, insertText));

    setTimeout(() => {
      e.target.setSelectionRange(insertPosition + insertText.length, insertPosition + insertText.length);
    }, 100)

    // 2022-09-17。下面的代码更接近直觉，但是有问题。在中文输入法下，插入文本后，光标会移动到末尾，且插入文本消失。
    // const oldText = e.target.value;
    // e.target.value = oldText.substr(0, insertPosition) + '    ' + oldText.substr(insertPosition);
    // e.target.setSelectionRange(insertPosition + insertText.length, insertPosition + insertText.length);
  }
  // 计算高度
  const refreshTwoContainerHeight = () => {
    const top = document.getElementById('two-container').getBoundingClientRect().top;
    if (top === 0) {
      return;
    }
    const viewportHeight = document.documentElement.getBoundingClientRect().height;
    const height = viewportHeight - top - 50;  // 50 是上层 element 的 margin
    if (twoContainerHeight !== height) {
      setTwoContainerHeight(height);
    }
  }
  useEffect(() => {
    window.addEventListener('resize', refreshTwoContainerHeight);
    return () => window.removeEventListener('resize', refreshTwoContainerHeight);
  }, [])
  useEffect(() => {
    refreshTwoContainerHeight();
  }, [editorConfig.layout])

  // url parameters
  const urlParams = useParams();
  useEffect(() => {
    // 由 url search 传参，改为 url router 传参
    // const pinIdFromUrl = searchParams.get('pin_id');
    const pinIdFromUrl = urlParams.pin_id;
    setPinId(pinIdFromUrl);
    refresh(pinIdFromUrl, true);
  }, [])
  // 快捷键
  useHotkeys('ctrl+s', (e, _) => {
    e.preventDefault();
    save(pinlog);
  }, { enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'] }, [pinlog]);

  return (<div style={{ margin: 0 }}>
    <EditPageContext.Provider value={{ pinlog, editorConfig, setEditorConfig }}>
      <TopButton />
      {/* Column One 布局 */}
      <div style={{ 'display': editorConfig.layout === COLUMN_TWO ? 'none' : 'inherit' }}>
        {/* ID */}
        <Divider />
        <Space direction='vertical'>
          <Space>
            <div style={{ width: 100 }}><Text>ID</Text></div>
            <div style={{ width: 164.67 }}><Text>{pinId}</Text></div>
            <div style={{ width: 40 }} />
            <div style={{ width: 100 }}><Text>Revision</Text></div>
            <Text>{pinlog?.pin?.revision}</Text>
          </Space>
          {/* pin time */}
          <Space>
            <div style={{ width: 100 }}><Text>Pin Time</Text></div>
            <Text>{timestampToText(pinlog.pin && pinlog.pin.pin_timestamp)}</Text>
            <div style={{ width: 40 }} />
            <div style={{ width: 100 }}><Text>To</Text></div>
            <DatePicker showTime value={viewTimestamp}
              onChange={value => {
                setViewTimestamp(value);
              }}
              onOk={value => {
                const timestamp = Math.floor(value.valueOf() / 1000);
                const pin = Object.assign({}, pinlog.pin, { 'pin_timestamp': timestamp });
                setPinlog(Object.assign({}, pinlog, { pin }));
              }}
            />
          </Space>
          {/* create time & modify time */}
          <Space>
            <div style={{ width: 100 }}><Text>Modify Time</Text></div>
            <Text>{timestampToText(pinlog.pin && pinlog.pin.modify_timestamp)}</Text>
            <div style={{ width: 40 }} />
            <div style={{ width: 100 }}><Text>Create Time</Text></div>
            <Text>{timestampToText(pinlog.pin && pinlog.pin.create_timestamp)}</Text>
          </Space>
        </Space>
        {/* Pin 和 Log */}
        <Divider>Pin</Divider>
        <Space direction='vertical'>
          <Space>
            <div style={{ width: 100 }}><Text></Text>Title</div>
            <Input style={{ width: 500 }} showCount maxLength={100} value={pinlog.pin && pinlog.pin.title}
              onChange={e => {
                const pin = Object.assign({}, pinlog.pin, { 'title': e.target.value });
                setPinlog(Object.assign({}, pinlog, { pin }));
              }} />
          </Space>
          <Space>
            <div style={{ width: 100 }}><Text>Abstract</Text></div>
            <Input.TextArea style={{ width: 500 }} maxLength={200} autoSize={{ minRows: 2 }} value={pinlog.pin && pinlog.pin.abstract}
              onChange={e => {
                const pin = Object.assign({}, pinlog.pin, { 'abstract': e.target.value });
                setPinlog(Object.assign({}, pinlog, { pin }));
              }} />
          </Space>
          <Space>
            <div style={{ width: 100 }}><Text>Content</Text></div>
            <Input.TextArea style={{ width: 500 }} maxLength={1000} autoSize={{ minRows: 4 }} value={pinlog.pin && pinlog.pin.content}
              onChange={e => {
                const pin = Object.assign({}, pinlog.pin, { 'content': e.target.value });
                setPinlog(Object.assign({}, pinlog, { pin }));
              }} />
          </Space>
        </Space>
        <Divider>Log</Divider>
        <Space direction='vertical'>
          <Space>
            <div style={{ width: 100 }}><Text>Title</Text></div>
            <Input style={{ width: 500 }} showCount maxLength={100} value={pinlog.log && pinlog.log.title}
              onChange={e => {
                const log = Object.assign({}, pinlog.log, { 'title': e.target.value });
                setPinlog(Object.assign({}, pinlog, { log }));
              }} />
          </Space>
          <Space>
            <div style={{ width: 100 }}><Text>Abstract</Text></div>
            <Input.TextArea style={{ width: 500 }} maxLength={200} autoSize={{ minRows: 2 }} value={pinlog.log && pinlog.log.abstract}
              onChange={e => {
                const log = Object.assign({}, pinlog.log, { 'abstract': e.target.value });
                setPinlog(Object.assign({}, pinlog, { log }));
              }} />
          </Space>
          <Space>
            <div style={{ width: 100 }}><Text>Content</Text></div>
            <Input.TextArea className="content-input" style={{ width: 500 }} maxLength={10000} autoSize={{ minRows: 8 }} value={pinlog.log && pinlog.log.content}
              onKeyDown={e => {
                // Tab 替换为 空格*4
                if (e.keyCode === 9) {
                  e.preventDefault();
                  insertTextToContent(e, '    ');
                }
              }}
              onChange={e => {
                const log = Object.assign({}, pinlog.log, { 'content': e.target.value });
                setPinlog(Object.assign({}, pinlog, { log }));
              }} />
          </Space>
          <Space>
            <div style={{ width: 100 }}></div>
            <div style={{ width: 200 }}>
              <Upload.Dragger {...uploadImageProps} onChange={
                (info) => {
                  const { status } = info.file;
                  if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                  }
                  if (status === 'done') {
                    // 插入到正文
                    const info_file = info.file;
                    const imageContent = fileToImageElementText(info_file.response.access_path);
                    const newPinlog = appendLogContent(pinlog, imageContent);
                    setPinlog(newPinlog);
                    // 弹窗提示
                    message.success(`${info_file.name} file uploaded successfully.`);
                  } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                  }
                }}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag image to this area to upload</p>
              </Upload.Dragger>
            </div>
            <div style={{ width: 200 }}>
              <Upload.Dragger {...uploadFileProps} onChange={
                (info) => {
                  const { status } = info.file;
                  if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                  }
                  if (status === 'done') {
                    // 插入到正文
                    const info_file = info.file;
                    const imageContent = `<a href="${info_file.response.access_path}" target="_blank">${info_file.name}</a>`;
                    let content = imageContent;
                    if (pinlog.log && pinlog.log.content) {
                      content = pinlog.log.content + '\n' + imageContent;
                    }
                    const log = Object.assign({}, pinlog.log, { 'content': content });
                    setPinlog(Object.assign({}, pinlog, { log }));
                    // 弹窗提示
                    message.success(`${info_file.name} file uploaded successfully.`);
                  } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                  }
                }}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </Upload.Dragger>
            </div>
            <div style={{ width: 200 }}>
              <Upload {...uploadBigFileProps} onChange={
                (info) => {
                  const { status } = info.file;
                  if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                  }
                  if (status === 'done') {
                    // 插入到正文
                    const info_file = info.file;
                    const imageContent = `<a href="${info_file.response.access_path}" target="_blank">${info_file.name}</a>`;
                    let content = imageContent;
                    if (pinlog.log && pinlog.log.content) {
                      content = pinlog.log.content + '\n' + imageContent;
                    }
                    const log = Object.assign({}, pinlog.log, { 'content': content });
                    setPinlog(Object.assign({}, pinlog, { log }));
                    // 弹窗提示
                    message.success(`${info_file.name} file uploaded successfully.`);
                  } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                  }
                }}>
                <Button icon={<UploadOutlined />}>Big file</Button>
              </Upload>
            </div>
          </Space>
        </Space>
        {/* Preview */}
        <Divider />
        <Space>
          <div style={{ width: 100 }}><Text>Preview</Text></div>
          <ContentPreview content={pinlog.log && pinlog.log.content} />
        </Space>
      </div>
      {/* Column Two 布局 */}
      <div style={{ 'display': editorConfig.layout === COLUMN_TWO ? 'inherit' : 'none' }}>
        <Divider />
        <div id='two-container' className='two-parent' style={{ 'height': twoContainerHeight }}>
          <div className='two-right two-child-column'>
            <Input.TextArea className="content-input" maxLength={10000} autoSize={{ minRows: 8 }} value={pinlog.log && pinlog.log.content}
              onKeyDown={e => {
                // Tab 替换为 空格*4
                if (e.keyCode === 9) {
                  e.preventDefault();
                  insertTextToContent(e, '    ');
                }
              }}
              onChange={e => {
                const log = Object.assign({}, pinlog.log, { 'content': e.target.value });
                setPinlog(Object.assign({}, pinlog, { log }));
              }} />
          </div>
          <div className='two-left two-child-column'>
            <ContentPreview content={pinlog.log && pinlog.log.content} columnLayout='column-two' />
            {/* Scroll View Tumb 宽 10，间隔 25 */}
            <div style={{ width: 35 }}>
            </div>
          </div>
        </div>
      </div>
    </EditPageContext.Provider>
  </div>);
}

export default EditPage;
